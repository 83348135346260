<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Kategori
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.name"
                v-validate="'required'"
                class="uk-input"
                type="text"
                name="nama"
                placeholder="Masukan nama kategori..."
                :class="{'uk-form-danger': errors.has('nama')}"
              >
              <div
                v-show="errors.has('nama')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('nama') }}
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-2" />
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-text-right uk-margin-top">
          <button
            class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToBack"
          >
            <img
              v-lazy="`${images}/icon/close.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Batal
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            @click="showSaveConfirmModal"
          >
            <img
              v-lazy="`${images}/icon/save.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  data() {
    return {
      isLoading: false,
      images: PREFIX_IMAGE,
      formData: {
        name: ''
      }
    }
  },
  methods: {
    ...mapMutations({
      setModalAdd: 'categorySop/SET_MODAL_ADD'
    }),
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setModalAdd(this.formData)
          window.UIkit.modal('#modal-save-confirm').show()
        }
      })
    }
  }
}
</script>
